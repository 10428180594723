import { Roboto } from 'next/font/google';
import "../i18n/config";
import Head from 'next/head';

const roboto = Roboto({
  weight: ['100', '300', '400', '500', '700', '900'],
  subsets: ['latin'],
});

function MyApp({ Component, pageProps }) {
  return (
    <main className={roboto.className}>
      {<Head>
        {
          process.env.NEXT_PUBLIC_SERVER == "production" && <script dangerouslySetInnerHTML={{
            __html: `(function() {
              var chklocation = document.location.href;
              // https://test.ideapoke.com/
              if (chklocation.indexOf("test.ideapoke.com") !== -1||chklocation.indexOf("jp.ideapoke.com") !== -1) {
              console.log("test123")
              }
              else if (chklocation.indexOf("ideapoke.com") !== -1 && chklocation.indexOf("www.ideapoke.com") === -1) {
                var referencesubdomain = "www.ideapoke.com";
                if (chklocation.indexOf("www.") < 0) {
                  var weburlval = chklocation.replace("https://", "");
                  document.location.href = "https://www." + weburlval;
                }
              }
            })();
              (function(h,o,t,j,a,r){
                  h.hj=h.hj||function(){(h.hj.q=h.hj.q||[]).push(arguments)};
                  h._hjSettings={hjid:2534714,hjsv:6};
                  a=o.getElementsByTagName('head')[0];
                  r=o.createElement('script');r.async=1;
                  r.src=t+h._hjSettings.hjid+j+h._hjSettings.hjsv;
                  a.appendChild(r);
                    })(window,document,'https://static.hotjar.com/c/hotjar-','.js?sv=');
            `,
          }} />

        }

      </Head>}
      <Component {...pageProps} />
    </main>
  );
}

export default MyApp;
